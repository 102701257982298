var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.data),function(item){return _c('div',{key:item.id,style:({ display: item.children ? 'block' : 'inline-block' })},[(item.children)?_c('div',[_c('div',{style:({
          margin:
            item.level == 1
              ? '5px 20px 5px 0'
              : item.level == 2
              ? '5px 20px 5px 20px'
              : '5px 20px 5px 40px',
          color:
            _vm.activeName == item.name
              ? 'red'
              : item.level == 1
              ? '#29303e'
              : item.level == 2
              ? '#29303eb4'
              : '#29303e5e',
        })},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('dataTree',{attrs:{"data":item.children}})],1):_c('div',{style:({
        margin:
          item.level == 1
            ? '5px 20px 5px 0'
            : item.level == 2
            ? '5px 20px 5px 20px'
            : '5px 20px 5px 40px',
        color:
          _vm.activeName == item.name
            ? '#fcad69'
            : item.level == 1
            ? '#29303e'
            : item.level == 2
            ? '#29303eb4'
            : '#29303e5e',
      })},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }