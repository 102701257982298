<template>
  <div class="body">
    <el-row>
      <el-col
        :span="4"
        style="border-right: 1px solid #f2f2f2; height: calc(100vh - 70px); position: relative"
      >
        <div
          style="
            box-sizing: border-box;
            margin: 20px;
            text-align: center;
            max-height: calc(100% - 105px);
            overflow-y: scroll;
          "
        >
          <span
            v-for="(name, index) in data"
            :key="index"
            :style="{
              background: id == name.id ? '#29303E' : '#F2F2F2',
              color: id == name.id ? '#FFF' : '#29303E',
              display: 'block',
              padding: '10px 0',
            }"
            @click="tab(name)"
            class="tabBtn"
          >
            {{ name.name }}
          </span>
        </div>
        <div class="btn1">
          <span v-if="id != 0" @click="editDepartment">编辑部门</span>
          <span v-if="id != 0" @click="delDepartment">删除部门</span>
        </div>
        <div class="btn">
          <span @click="dialogFormVisibleOne = true">创建部门</span>
          <span @click="[(dialogFormVisibleTwo = true), (createTitle = '创建角色')]">创建角色</span>
        </div>
      </el-col>
      <el-col :span="20" style="padding: 20px">
        <div style="margin: 0 20px 20px 0">
          <el-row>
            <el-col :span="7">
              <div style="display: flex; align-items: center; margin-right: 30px">
                <span style="display: block; width: 50px">姓名：</span>
                <el-input
                  v-model="input.name"
                  style=""
                  placeholder="请输入需要查询的姓名"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="7">
              <div style="display: flex; align-items: center; margin-right: 30px">
                <span style="display: block; width: 65px">手机号：</span>
                <el-input
                  v-model="input.phone"
                  style=""
                  placeholder="请输入需要查询的手机号"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="7">
              <div style="display: flex; align-items: center; margin-right: 30px">
                <span style="display: block; width: 50px">邮箱：</span>
                <el-input
                  v-model="input.email"
                  style=""
                  placeholder="请输入需要查询的邮箱"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="3">
              <el-button style="background-color: #29303e; color: white" @click="inquireData()">
                查询
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div style="width: 100%; height: 650px; overflow-y: scroll">
          <el-table
            :header-cell-style="{ background: '#F2F2F2', textAlign: 'center' }"
            :cell-style="{ textAlign: 'center', cursor: 'pointer' }"
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column prop="nickname" label="姓名">
              <template #default="scope">
                <div style="display: flex; align-items: center">
                  <p class="nameLogo">
                    {{ captureName(scope.row.nickname) }}
                  </p>
                  <span>
                    {{ scope.row.nickname }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="role" label="部门"></el-table-column>
            <el-table-column prop="mobile" label="手机号码"></el-table-column>
            <el-table-column prop="email" label="邮箱"></el-table-column>
            <el-table-column prop="createtime" label="创建时间"></el-table-column>
            <el-table-column prop="status" label="权限范围">
              <template #default="scope">
                <!-- :disabled="!permissionsAction.status" -->
                <!-- {{ permissionsAction }} -->
                <el-button type="text" @click="Get_auth(scope.row)">查看</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="角色状态">
              <template #default="scope">
                <div v-if="scope.row.status == 2">
                  <el-button type="success" size="mini" plain>待进入</el-button>
                </div>
                <div v-else-if="scope.row.status == 1">
                  <el-button type="primary" size="mini" plain>使用中</el-button>
                </div>
                <div v-else-if="scope.row.status == 0">
                  <el-button type="info" size="mini" plain>已停用</el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作">
              <template #default="scope">
                <!-- :disabled="!permissionsAction.status" -->
                <el-button @click="enable(scope.row)" type="text" size="small">
                  {{ scope.row.status == 0 ? '启用' : '禁用' }}
                </el-button>
                <el-button @click="editTitle(scope.row)" type="text" size="small">编辑</el-button>
                <el-button
                  v-if="scope.row.is_role == 1"
                  @click="makeOverEdit(scope.row)"
                  type="text"
                  size="small"
                >
                  转让
                </el-button>

                <el-button @click="showRole(scope.row)" type="text" size="small">
                  设置角色
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="text-align: center; margin-top: 25px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.page"
            :page-sizes="[10, 15, 30, 35]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.totalItems"
          ></el-pagination>
        </div>
      </el-col>
    </el-row>
    <!-- 新增部门 编辑部门页 -->
    <div>
      <el-dialog title="创建部门" :visible.sync="dialogFormVisibleOne">
        <el-form :model="departmentForm" :rules="rules">
          <el-form-item label="部门名称" :label-width="formLabelWidth" prop="name">
            <el-input style="width: 400px" v-model="departmentForm.name"></el-input>
          </el-form-item>
          <el-form-item label="部门权限" :label-width="formLabelWidth" prop="permissions">
            <!-- 树形结构 -->
            <div class="repair-order-modules">
              <el-tree
                ref="tree"
                :data="permissions"
                show-checkbox
                default-expand-all
                :indent="24"
                node-key="id"
                highlight-current
                :props="defaultProps"
                :expand-on-click-node="false"
              >
                <template v-slot="{ node, data }">
                  <div
                    :class="{
                      'need-float': data.level === 3 || data.level === 2,
                    }"
                  >
                    {{ node.label }}
                  </div>
                </template>
              </el-tree>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="medium" @click="goBack">返 回</el-button>
          <el-button size="medium" type="primary" @click="handleAuthConfigSaveBtn">保 存</el-button>
        </div>
      </el-dialog>
      <el-dialog title="编辑部门" :visible.sync="dialogFormVisibleOne_edit">
        <el-form :model="departmentForm" :rules="rules">
          <el-form-item label="部门名称" :label-width="formLabelWidth" prop="name">
            <el-input style="width: 400px" v-model="departmentForm.name"></el-input>
          </el-form-item>
          <el-form-item label="部门权限" :label-width="formLabelWidth" prop="permissions">
            <!-- 树形结构 -->
            <div class="repair-order-modules">
              <el-tree
                ref="tree"
                :data="permissions"
                show-checkbox
                default-expand-all
                :indent="24"
                node-key="id"
                highlight-current
                :props="defaultProps"
                :expand-on-click-node="false"
              >
                <template v-slot="{ node, data }">
                  <div
                    :class="{
                      'need-float': data.level === 3 || data.level === 2,
                    }"
                  >
                    {{ node.label }}
                  </div>
                </template>
              </el-tree>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="medium" @click="goBack">返 回</el-button>
          <el-button size="medium" type="primary" @click="editBtn">保存编辑</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 创建角色页 -->
    <div>
      <el-dialog
        :title="createTitle"
        top="50px"
        width="400px"
        :visible.sync="dialogFormVisibleTwo"
        :close-on-click-modal="false"
      >
        <el-form :model="roleForm" ref="ruleForm" :rules="rules">
          <el-form-item label="姓名" prop="name1">
            <el-input v-model="roleForm.name1"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="roleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="密码" :prop="createTitle === '创建角色' ? 'passWord' : ''">
            <el-input type="password" v-model="roleForm.passWord"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" :prop="createTitle === '创建角色' ? 'passWord1' : ''">
            <el-input type="password" v-model="roleForm.passWord1"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="roleForm.email"></el-input>
          </el-form-item>
          <el-form-item label="渲染" prop="is_render">
            <el-radio v-model="roleForm.is_render" :label="0">否</el-radio>
            <el-radio v-model="roleForm.is_render" :label="1">是</el-radio>
          </el-form-item>

          <el-form-item label="数据范围" prop="data_range">
            <el-select v-model="roleForm.data_range_type" placeholder="请选择">
              <el-option
                v-for="item in dataRanges"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="所属部门" prop="resource">
            <el-select v-model="roleForm.resource" multiple placeholder="请选择所属部门">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="goBack">取 消</el-button>
          <el-button type="primary" @click="createRole">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 权限查看页 -->
    <div>
      <el-dialog title="权限范围" :visible.sync="jurisdictionShow">
        <div style="">
          <span
            style="display: inline-block; background-color: #29303e; width: 15px; height: 15px"
          ></span>
          一级权限
          <span
            style="display: inline-block; background-color: #29303eb4; width: 15px; height: 15px"
          ></span>
          二级权限
          <span
            style="display: inline-block; background-color: #29303e5e; width: 15px; height: 15px"
          ></span>
          三级权限
        </div>
        <DataTree :data="permissionViewdata" />
      </el-dialog>
    </div>
    <!-- 转让页 -->
    <div>
      <el-dialog title="转让权限" width="240px" :visible.sync="makeOver">
        <el-autocomplete
          v-model="status"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入内容"
          @select="handleSelect"
        ></el-autocomplete>
        <div style="text-align: center; margin: 20px auto">
          <el-button type="info" plain @click="btnRestaurants">确定转让</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 设置角色 -->
    <el-dialog
      title="设置角色"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisibleRole"
      width="30%"
      destroy-on-close
    >
      <div>
        <el-form size="mini" label-width="80px">
          <el-form-item label="菜单">
            <el-select v-model="user_roles" multiple placeholder="请选择">
              <el-option
                v-for="item in roles"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormVisibleRole = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="saveUserRoles()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  userGet_role,
  getAuthConfigList,
  userList,
  userSave_group,
  authGet_auth,
  userEdit_group,
  usergGet_auth,
  usergDelete_group,
  distribute_account,
  account_forbidden,
  administrator_transfer,
  edit_distribute_account,
} from '../../common/groupList';
import { getRbacRole, saveRbacRoles } from '@/common/rbac.js';

import DataTree from '../../components/dataTree.vue';
export default {
  name: 'groupList',
  components: { DataTree },
  data() {
    // var validatePass = (rule, value, callback) => {
    //     if (value === "") {
    //         callback(new Error('请输入密码'));
    //     } else {
    //         if (this.roleForm.passWord1 !== "") {
    //             this.$refs.ruleForm.validateField('passWord1');
    //         }
    //         callback();
    //     }
    // };
    // var validatePass2 = (rule, value, callback) => {
    //     if (value === "") {
    //         callback(new Error('请再次输入密码'));
    //     } else if (value !== this.roleForm.passWord) {
    //         console.log(value);
    //         console.log(this.roleForm.passWord);
    //         callback(new Error('两次输入密码不一致!'));
    //     } else {
    //         callback();
    //     }
    // };
    return {
      status: '',
      // 选中搜索数据
      restaurantsData: {},
      // 搜索数据
      restaurants: [],
      // 转让权限页
      makeOver: false,
      // 权限查看页数据
      permissionViewdata: [],
      // 分组列表数据
      data: [],
      // 选中标签
      pitchName: '所有成员',
      // 选中的ID
      id: 0,
      dialogFormVisibleRole: false,
      // 创建部门页
      dialogFormVisibleOne: false,
      // 编辑部门页
      dialogFormVisibleOne_edit: false,
      // 创建角色页
      dialogFormVisibleTwo: false,
      // 权限查看页面
      jurisdictionShow: false,
      // 部门信息
      departmentForm: {
        name: '',
      },
      dataRanges: [
        { label: '全部', value: 1 },
        { label: '自定义部门', value: 2 },
        { label: '本部门及子部门', value: 3 },
        { label: '本部门', value: 4 },
        { label: '本人', value: 5 },
      ],
      // 角色信息
      roleForm: {
        name1: '',
        phone: '',
        passWord: '',
        passWord1: '',
        email: '',
        data_range_type: 1,
        resource: '',
        is_render: 0,
      },
      formLabelWidth: '120px',
      // 权限操作集合
      permissionsAction: {},
      // 分页
      page: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
      },
      // 右侧人物数据列表
      tableData: [],
      // 树结构规则
      defaultProps: {
        children: 'child',
        label: 'name',
      },
      // 选择所有权限数据
      permissions: {},
      createTitle: '创建角色',
      // 是否必填
      rules: {
        name: [{ required: true, message: '请输入部门名称', trigger: 'blur' }],
        permissions: [{ required: true, message: '请选择部门权限', trigger: 'blur' }],
        name1: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        passWord: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        passWord1: [{ required: true, message: '请输入确认密码', trigger: 'blur' }],
        // passWord: [{ required: true, validator: validatePass, trigger: 'blur' }],
        // passWord1: [{ required: true, validator: validatePass2, trigger: 'blur' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
        resource: [{ required: true, message: '请选择所属部门', trigger: 'blur' }],
        is_render: [{ required: true, message: '是否可以渲染', trigger: 'blur' }],
      },
      // 搜索框
      input: {
        name: '',
        phone: '',
        email: '',
      },
      roles: [],
      user_roles: [],
      user_id: null,
      options: [],
    };
  },
  // 监听 关闭页面清除数据
  watch: {
    dialogFormVisibleOne() {
      if (this.dialogFormVisibleOne == false) {
        this.departmentForm = {
          name: '',
        };
        this.$refs.tree.setCheckedKeys([]);
      }
    },
    dialogFormVisibleOne_edit() {
      if (this.dialogFormVisibleOne_edit == false) {
        this.departmentForm = {
          name: '',
        };
        this.$refs.tree.setCheckedKeys([]);
      }
    },
    dialogFormVisibleTwo() {
      if (this.dialogFormVisibleTwo == false) {
        this.createTitle = '创建角色';
        this.roleForm = {
          id: '',
          name1: '',
          phone: '',
          passWord: '',
          passWord1: '',
          email: '',
          resource: '',
        };
      }
    },
    makeOver() {
      if (this.makeOver == false) {
        this.status = '';
      }
    },
  },
  methods: {
    async showRole(row) {
      let param = { uid: row.id };
      this.user_id = row.id;
      let resp = await getRbacRole(param);
      // console.log(resp, row);
      this.dialogFormVisibleRole = true;
      this.roles = resp.result.roles;
      this.user_roles = resp.result.user_roles;
    },
    async saveUserRoles() {
      let param = {
        uid: this.user_id,
        user_roles: this.user_roles,
      };
      let resp = await saveRbacRoles(param);
      console.log(resp);
      this.dialogFormVisibleRole = false;
      this.$notify({
        title: '成功',
        message: '数据修改成功',
        type: 'success',
      });
    },
    // 编辑员工
    editTitle(row) {
      console.log(row);
      this.createTitle = '编辑角色';
      this.dialogFormVisibleTwo = true;
      this.roleForm = {
        id: row.id,
        name1: row.nickname,
        phone: row.mobile,
        data_range_type: row.data_range.type,
        passWord: '',
        passWord1: '',
        email: row.email,
        resource: row.group_id,
        is_render: row.is_render,
      };
    },
    // 查询按钮
    inquireData() {
      // this.page = {
      //     page: 1,
      //     pageSize: 10,
      // }
      this.page.page = 1;
      this.page.pageSize = 10;
      this.tabBtn({ name: this.pitchName, id: this.id });
    },
    // 获取名字最后两个字
    captureName(data) {
      let item = '';
      if (data.length > 1) {
        item = data.substr(data.length - 2, 2);
      } else {
        item = data;
      }
      return item;
    },
    // 启用禁用
    enable(row) {
      let dt = {
        id: row.id,
        status: row.status == 0 ? 1 : 0,
      };
      account_forbidden(dt).then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.$message.success(msg);
          this.tabBtn({
            name: this.pitchName,
            id: this.id,
          });
        }
      });
    },
    // 分页
    handleSizeChange(size) {
      this.page.pageSize = size;
      let name = {
        name: this.pitchName,
        id: this.id,
      };
      this.tabBtn(name);
    },
    handleCurrentChange(page) {
      this.page.page = page;
      let name = {
        name: this.pitchName,
        id: this.id,
      };
      this.tabBtn(name);
    },
    // 分组列表
    authList() {
      userGet_role().then((res) => {
        this.data = [];
        this.data = res.result;
        let arr = res.result;
        this.options = arr.slice(0);
        this.data.unshift({
          name: '所有成员',
          id: 0,
        });
        this.tabBtn({
          name: '所有成员',
          id: 0,
        });
      });
    },
    // 权限配置所有配置数据
    queryAuthConfigData() {
      getAuthConfigList().then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.permissions = result;
        }
      });
    },
    // 账号权限
    getAuth() {
      authGet_auth({ GroupID: this.id }).then((res) => {
        let { code, result } = res;
        if (code == 200) {
          setTimeout(() => {
            result.forEach((id) => {
              let node = this.$refs.tree.getNode(id);
              if (node && node.isLeaf) {
                this.$refs.tree.setChecked(id, true);
              }
            });
          }, 10);
          this.departmentForm.name = this.pitchName;
        }
      });
    },
    // 创建部门确定按钮
    handleAuthConfigSaveBtn() {
      // 选中节点的数据
      let getCheckedKeys = this.$refs.tree.getCheckedKeys();
      // 半选中节点的数据
      let getHalfCheckedKeys = this.$refs.tree.getHalfCheckedKeys();
      let checkKeys = [...getHalfCheckedKeys, ...getCheckedKeys];
      let params = {
        group_name: this.departmentForm.name,
        auth_ids: checkKeys,
      };
      userSave_group(params).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: `${res.msg}`,
            center: true,
          });
          this.authList();
          this.dialogFormVisibleOne = false;
        }
      });
    },
    // 编辑部门确定按钮
    editBtn() {
      // 选中节点的数据
      let getCheckedKeys = this.$refs.tree.getCheckedKeys();
      // 半选中节点的数据
      let getHalfCheckedKeys = this.$refs.tree.getHalfCheckedKeys();
      let checkKeys = [...getHalfCheckedKeys, ...getCheckedKeys];
      let params = {
        group_id: this.id,
        group_name: this.departmentForm.name,
        auth_ids: checkKeys,
        describe: '',
      };
      userEdit_group(params).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: `${res.msg}`,
            center: true,
          });
          this.authList();
          this.dialogFormVisibleOne_edit = false;
        }
      });
    },
    // 删除部门确定按钮
    delDepartment() {
      this.$confirm('确认删除吗?', '提示', {})
        .then(() => {
          usergDelete_group({ group_id: this.id }).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: `${res.msg}`,
                center: true,
              });
              this.authList();
            }
          });
        })
        .catch(() => {});
    },
    // 创建角色确定按钮
    createRole() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.createTitle == '编辑角色') {
            let dt = {
              id: this.roleForm.id,
              nickname: this.roleForm.name1,
              mobile: this.roleForm.phone,
              email: this.roleForm.email,
              roles: this.roleForm.resource,
              data_range_type: this.roleForm.data_range_type,
              password: this.roleForm.passWord,
              password1: this.roleForm.passWord1,
              is_render: this.roleForm.is_render,
            };
            edit_distribute_account(dt).then((res) => {
              let { code, msg } = res;
              if (code == 200) {
                this.$message.success(msg);
                this.dialogFormVisibleTwo = false;
                this.tabBtn({
                  name: this.pitchName,
                  id: this.id,
                });
                this.roleForm = {
                  id: '',
                  name1: '',
                  phone: '',
                  passWord: '',
                  passWord1: '',
                  email: '',
                  data_range_type: 1,
                  resource: '',
                  is_render: 0,
                };
              } else {
                this.$message.warning(msg);
              }
            });
          } else {
            let dt = {
              nickname: this.roleForm.name1,
              mobile: this.roleForm.phone,
              email: this.roleForm.email,
              roles: this.roleForm.resource,
              password: this.roleForm.passWord,
              password1: this.roleForm.passWord1,
              is_render: this.roleForm.is_render,
            };
            distribute_account(dt).then((res) => {
              let { code, msg } = res;
              if (code == 200) {
                this.$message.success(msg);
                this.dialogFormVisibleTwo = false;
                this.tabBtn({
                  name: this.pitchName,
                  id: this.id,
                });
                this.roleForm = {
                  name1: '',
                  phone: '',
                  passWord: '',
                  passWord1: '',
                  email: '',
                  data_range_type: 1,
                  resource: '',
                  is_render: 0,
                };
              } else {
                this.$message.warning(msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    // 取消按钮
    goBack() {
      this.dialogFormVisibleOne = false;
      this.dialogFormVisibleOne_edit = false;
      this.dialogFormVisibleTwo = false;
    },
    // 切换按钮
    tab(name) {
      this.page.page = 1;
      this.page.pageSize = 10;
      this.tabBtn(name);
    },
    tabBtn(name) {
      this.pitchName = name.name;
      this.id = name.id;
      let dt = {};
      if (name == 0) {
        dt['group_id'] = 0;
        dt['name'] = this.input.name;
        dt['mobile'] = this.input.phone;
        dt['email'] = this.input.email;
        dt['page'] = this.page.page;
        dt['pagesize'] = this.page.pageSize;
      } else {
        dt['group_id'] = this.id;
        dt['name'] = this.input.name;
        dt['mobile'] = this.input.phone;
        dt['email'] = this.input.email;
        dt['page'] = this.page.page;
        dt['pagesize'] = this.page.pageSize;
      }
      userList(dt).then((res) => {
        this.tableData = res.result.data;
        this.page.totalItems = res.result.count;
        this.permissionsAction = res.result.auth;
      });
    },
    // 打开编辑部门按钮
    editDepartment() {
      this.dialogFormVisibleOne_edit = true;
      this.getAuth();
    },
    // 列表查看按钮
    Get_auth(e) {
      usergGet_auth({ GroupIDs: e.group_id }).then((res) => {
        let { code, result } = res;
        if (code == 200) {
          this.permissionViewdata = result;
          this.jurisdictionShow = true;
        }
      });
    },
    makeOverEdit(item) {
      this.restaurantsData['selfId'] = item.id;
      this.makeOver = true;
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;
      results.forEach((res) => {
        res.value = res.nickname;
      });
      cb(results);
    },
    createStateFilter(queryString) {
      return (state) => {
        return state.value.includes(queryString);
      };
    },
    // 选中要转让的权限
    handleSelect(item) {
      this.restaurantsData = { ...this.restaurantsData, ...item };
    },
    // 转让权限确定按钮
    btnRestaurants() {
      let dt = {
        recipient_id: this.restaurantsData.id,
        operator_id: this.restaurantsData.selfId,
      };
      administrator_transfer(dt).then((res) => {
        let { code, msg } = res;
        if (code == 200) {
          this.$message.success(msg);
          this.makeOver = false;
          this.tabBtn({
            name: this.pitchName,
            id: this.id,
          });
        }
      });
    },
  },
  async created() {
    this.authList();
    await this.queryAuthConfigData();
    let dt = {};
    dt['group_id'] = 0;
    dt['page'] = 1;
    dt['pagesize'] = 99999;
    await userList(dt).then((res) => {
      if (res.code == 200) {
        this.restaurants = res.result.data;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.nameLogo {
  background-color: #e8e8e8;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  margin: 0 10px 0 0;
}

.body {
  font-size: 13px;
  height: calc(100vh - 65px);
  background-color: #ffffff;

  .btn {
    position: absolute;
    bottom: 10px;
    width: 100%;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
  }

  .btn1 {
    position: absolute;
    bottom: 40px;
    width: 100%;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
  }

  .btn span,
  .btn1 span {
    padding: 0 20px;
  }

  .btn span:hover,
  .btn1 span:hover {
    background-color: #29303e;
    color: white;
  }

  .dialog-footer {
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }

  ::v-deep .el-select {
    width: 100%;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    // width: 100%;
    background-color: #29303e;
  }

  // ::v-deep .number:hover {
  //     color: white !important;
  // }

  .repair-order-modules {
    margin-top: 10px;
    height: 450px;
    overflow-y: scroll;
  }

  .need-float {
    float: left;
  }
}

.tabBtn:hover {
  background-color: #29303e7a !important;
  cursor: pointer;
  color: white !important;
}
</style>
