<template>
  <div>
    <div
      v-for="item in data"
      :key="item.id"
      :style="{ display: item.children ? 'block' : 'inline-block' }"
    >
      <div v-if="item.children">
        <div
          :style="{
            margin:
              item.level == 1
                ? '5px 20px 5px 0'
                : item.level == 2
                ? '5px 20px 5px 20px'
                : '5px 20px 5px 40px',
            color:
              activeName == item.name
                ? 'red'
                : item.level == 1
                ? '#29303e'
                : item.level == 2
                ? '#29303eb4'
                : '#29303e5e',
          }"
        >
          {{ item.name }}
        </div>
        <dataTree :data="item.children" />
      </div>
      <div
        v-else
        :style="{
          margin:
            item.level == 1
              ? '5px 20px 5px 0'
              : item.level == 2
              ? '5px 20px 5px 20px'
              : '5px 20px 5px 40px',
          color:
            activeName == item.name
              ? '#fcad69'
              : item.level == 1
              ? '#29303e'
              : item.level == 2
              ? '#29303eb4'
              : '#29303e5e',
        }"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import dataTree from './dataTree';
export default {
  name: 'dataTree',
  props: {
    data: {
      type: Array,
    },
    activeName: String,
  },
  watch: {
    // $route: {
    //     immediate: true, // 一旦监听到路由的变化立即执行
    //     $route(to, from) {
    //         console.log(to);
    //         console.log(from);
    //         debugger;
    //     },
    // },
    activeName() {
      console.log(this.activeName);
    },
  },
  components: {
    dataTree,
  },
  data() {
    return {
      // activeName: '',
    };
  },
};
</script>

<style lang="scss" scoped>
body {
  background-color: #29303e5e;
}
</style>
